import React from "react"
import { Fade } from "react-reveal"

const Paragraph: React.FC<any> = (paragraph) => {
  return (
    <div className="c-paragraph u-txtA(c) l-flx(aic) rellax" data-rellax-speed="-1" data-rellax-xs-speed="-0.2" data-rellax-mobile-speed="-0.2">
      <Fade bottom>
        <h2>{paragraph.fieldTitolo}</h2>
        <p>
          {paragraph.fieldTesto}
        </p>
      </Fade>
    </div>
  )
}

export default Paragraph
