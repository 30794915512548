import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { graphql } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../redux/store"
import { setPage } from "../redux/reducers/indexSlice"
import Paragraph from "../components/paragraph"
import TwoImgPromo from "../components/two_img_promo"
import { PromotionItem } from "../components/promotions"

const Promozioni: React.FC<any> = ({ data }) => {
  const language = useSelector((state: RootState) => state.inner.language)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPage(2))
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Banner
        title={data.page[language.toLowerCase()].title}
        image={data.page[language.toLowerCase()].fieldImg}
      />
      <Paragraph {...data.page[language.toLowerCase()].fieldContenuti[0].entity} />
      <div className="c-promotions__promosWrapper">
        {
          data.promotions.nodeQuery[language.toLowerCase()].map((node, index) => {
            if (index === 0 || index % 4 === 0) {
              return <TwoImgPromo {...node} isInverted={index % 4 === 0 && index % 8 !== 0}/>
            } else {
              return <PromotionItem {...node} index={index}/>
            }
          })
        }
      </div>
    </Layout>
  )
}

export default Promozioni

export const query = graphql`
    query {
        page: drupal {
          it: nodeById(id: "18", language: IT) {
            ... on Drupal_NodePaginaBase {
              nid
              title
              fieldImg {
                alt
                url
              }
              fieldContenuti {
                entity {
                  ... on Drupal_ParagraphUnaColonna {
                    id
                    fieldTitolo
                    fieldTesto
                  }
                }
              }
            }
          }
          en: nodeById(id: "18", language: EN) {
            ... on Drupal_NodePaginaBase {
              nid
              title
              fieldImg {
                alt
                url
              }
              fieldContenuti {
                entity {
                  ... on Drupal_ParagraphUnaColonna {
                    id
                    fieldTitolo
                    fieldTesto
                  }
                }
              }
            }
          }
        }
        promotions: drupal {
          nodeQuery(limit: 30, offset: 0, filter: {conditions: [
            {field: "type", operator: EQUAL, value: ["promozione"]}
          ]}) {
            it: entities(language: IT) {
              ... on Drupal_NodePromozione {
                nid
                fieldImmagini {
                  alt
                  url
                }
                title
                fieldSconto
                fieldLink
                fieldAbstract
              }
            }
            en: entities(language: EN) {
              ... on Drupal_NodePromozione {
                nid
                fieldImmagini {
                  alt
                  url
                }
                title
                fieldSconto
                fieldLink
                fieldAbstract
              }
            }
          }
        }
      }
`