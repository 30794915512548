import React from "react"
import ParagraphLink from "./paragraph_link"

const TwoImgPromo: React.FC<any> = (node) => {
  return (
    <div className={`c-twoImgPromotion ${node.isInverted ? 'c-twoImgPromotion--inverted' : ''}`}>
      <ParagraphLink {...node}/>
      <div className="c-twoImgPromotion__imgWrapper">
        <div className="o-sale">-{node.fieldSconto}%</div>
        {node.fieldImmagini[0] && <img src={node.fieldImmagini[0].url} alt={node.fieldImmagini[0].alt} />}
        {node.fieldImmagini[1] && <img src={node.fieldImmagini[1].url} alt={node.fieldImmagini[1].alt} />}
      </div>
    </div>
  )
}

export default TwoImgPromo
