import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import messages from "../assets/messages.json"

const ParagraphLink: React.FC<any> = (node) => {
  const language = useSelector((state: RootState) => state.inner.language)
  return (
    <div className="c-paragraph u-txtA(l) l-flx(aifs)">
      <h3>{node.title}</h3>
      <p>
        {node.fieldEvidenza}
      </p>
      <p className="c-paragraph__thick">
        {node.fieldAbstract}
      </p>
      <div className="l-flex">
        <Link to={node.fieldLink} className="o-btn(arrowRight)">
          {messages[language.toLowerCase()].contactUs}
        </Link>
      </div>
    </div>
  )
}

export default ParagraphLink
