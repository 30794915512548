import React from "react"
import Carousel, { consts } from "react-elastic-carousel"

import { useSelector } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"

const Promotions = () => {
  const language = useSelector((state) => state.inner.language)
  const data = useStaticQuery(graphql`
    query {
      paragraph: drupal {
        it: paragraphById(id: "14", language: IT) {
          ... on Drupal_ParagraphUnaColonna {
            id
            fieldTitolo
            fieldTesto
          }
        }
        en: paragraphById(id: "14", language: EN) {
          ... on Drupal_ParagraphUnaColonna {
            id
            fieldTitolo
            fieldTesto
          }
        }
      }
      promotions: drupal {
        nodeQuery(limit: 30, offset: 0, filter: {conditions: [{field: "type", operator: EQUAL, value: ["promozione"]}]}) {
          it: entities(language: IT) {
            ... on Drupal_NodePromozione {
              nid
              fieldImmagini {
                alt
                url
              }
              title
              fieldSconto
            }
          }
          en: entities(language: EN) {
            ... on Drupal_NodePromozione {
              nid
              fieldImmagini {
                alt
                url
              }
              title
              fieldSconto
            }
          }
        }
      }
    }
  `)
  return (
    <div className="c-promotions">
      <div>
        <h3>{data.paragraph[language.toLowerCase()].fieldTitolo}</h3>
        <p>{data.paragraph[language.toLowerCase()].fieldTesto}</p>
      </div>
      <Carousel breakPoints={[
        {
          width: 1, itemsToShow: 1
        },
        {
          width: 500, itemsToShow: 2
        },
        {
          width: 1200, itemsToShow: 3
        }
      ]} initialFirstItem={Math.round(data.promotions.nodeQuery[language.toLowerCase()].length / 3)} itemPosition={consts.CENTER} pagination={false}>
        {
          data.promotions.nodeQuery[language.toLowerCase()].map((node) => {
            return (
              <PromotionItem {...node} key={`promo-${node.nid}`}/>
            )
          })
        }
      </Carousel>
    </div>
  )
}

export default Promotions

export const PromotionItem = (item) => {
  return (
    <div className="c-promotions__carouselItem" style={{ margin: item.index && (item.index % 4 === 2 || item.index === 2) ? '0 50px 0 50px' : '', padding: item.index ? 0 : '' }}>
      <div>
        <img src={item.fieldImmagini[0].url} alt={item.fieldImmagini[0].alt} />
      </div>
      <h6>{item.title}</h6>
      <div className="o-sale" style={{ top: item.index ? 15 : '', right: item.index ? 15 : '' }}>
        -{item.fieldSconto}%
        </div>
    </div>
  )
}
